//content
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Doughnut } from 'react-chartjs-2';
import CreditScoreGraph from './LineChart';

export default function CalculatorModuleNew (props) {

  var Arr =[]

  const [loader, setLoader] = useState(false)
  const [form, setForm] = useState(false)
  const [text, setText] = useState('')

  const [baseline, setBaseline] = useState({
    base1: '55967', base2: '575', base3: '6932', 
      base4: '25277', base5: '9151', base6: '100',
  })
  const [basescore, setBaseScore] = useState('000')
  
  const [score, setScore] = useState('0')
  const [scorelist, setScoreList] = useState([])
  // add scores every 5 seconds into scorelist for plotting into line graph

  const [alerts, setAlerts] = useState([
    {status: '1', memo: 'Credit Score is below range. Monitoring Needed', created: 1722308585084 },
    {status: '0', memo: 'Credit Score is below threshold. Action Needed', created: 1722308485084 },
  ])
  // add comments when new score added to scorelist, score :: 000-330 == status 0, score :: 330-630 == status 1

  const [rates, setRates] = useState('000')
  const [rate, setRate] = useState({
    rate1: '',
    rate2: '',
    rate3: '', 
    rate4: '', 
    rate5: '', 
    rate6: ''
  })
  const [data, setData] = useState({
    data1: '',
    data2: '',
    data3: '', 
    data4: '', 
    data5: '', 
    data6: '',
  })

  useEffect(() => {
    setRates(parseInt(rate?.rate1 || '0')+parseInt(rate?.rate2 || '0')+parseInt(rate?.rate3 || '0')+parseInt(rate?.rate4 || '0')+parseInt(rate?.rate5 || '0')+parseInt(rate?.rate6 || '0'))
    
  }, [rate])


  useEffect(() => {
    var score1 = baseline.base1>0 ? (data.data1 >= baseline.base1 ? baseline.base1 : data.data1) / baseline.base1 * rate.rate1 * 9 : 0
    var score2 = baseline.base2>0 ? (data.data2 >= baseline.base2 ? baseline.base2 : data.data2) / baseline.base2 * rate.rate2 * 9 : 0
    var score3 = baseline.base3>0 ? (data.data3 >= baseline.base3 ? baseline.base3 : data.data3) / baseline.base3 * rate.rate3 * 9 : 0
    var score4 = baseline.base4>0 ? (data.data4 >= baseline.base4 ? baseline.base4 : data.data4) / baseline.base4 * rate.rate4 * 9 : 0
    var score5 = baseline.base5>0 ? (data.data5 >= baseline.base5 ? baseline.base5 : data.data5) / baseline.base5 * rate.rate5 * 9 : 0
    var score6 = baseline.base6>0 ? (data.data6 >= baseline.base6 ? baseline.base6 : data.data6) / baseline.base6 * rate.rate6 * 9 : 0

    setScore((score1+score2+score3+score4+score5+score6).toFixed(0))

  }, [rate, baseline, data])

  // console.log(score)

  useEffect(()=>{
    // Arr.push(score)
    // setScoreList(scorelist=> [...scorelist ,  ...Arr])
    setScoreList(scorelist=> [...scorelist, score])
   
  },[score])


  useEffect(() => {
    const intervalId = setInterval(() => {
      setScoreList((prevArray) => {
        const newElement = score; // Use the latest score value
        return [...prevArray, newElement]; // Add the new element to the end of the array
      });

      if (score >= 630 ) {
        const obj = {
          status: '6',
          memo: 'Credit Score is Ok. Continue Monitoring',
          created: Date.now()
        };
        setAlerts((prevAlerts) => [obj, ...prevAlerts]);
      }

      if (score >= 330 && score < 630) {
        const obj = {
          status: '1',
          memo: 'Credit Score is below range. Monitoring Needed',
          created: Date.now()
        };
        setAlerts((prevAlerts) => [obj, ...prevAlerts]);
      }

      if (score < 330) {
        const obj = {
          status: '0',
          memo: 'Credit Score is below threshold. Action Needed',
          created: Date.now()
        };
        setAlerts((prevAlerts) => [obj, ...prevAlerts]);
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [score]);


  const handleRateChange = async (key, val) => {
    setRate({ ...rate, [key]: val })
  }

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val })
  }

  const handleBaseline = async (x) => {
    // alert(x)
    if (x==1) setBaseline({
      base1: '55967', base2: '575', base3: '6932', 
      base4: '25277', base5: '9151', base6: '100', 
    })

    if (x==2) setBaseline({
      base1: '159345', base2: '735', base3: '15931', 
      base4: '255292', base5: '56423', base6: '100', 
    })

    if (x==3) setBaseline({
      base1: '24237', base2: '457', base3: '1137', 
      base4: '5379', base5: '0', base6: '100', 
    })

  }

  // console.log(scorelist)

  return (
    <>
      {/* info */}


      <div className='d-flex'>
        
        <div className='d-flex justify-content-start bg-light rounded border' style={{width: '55%'}}>
          <div className='' style={{width: '30%'}}>
            <p className='fw-bold mt-3 ms-3'>Domain</p>
            <div className='border-bottom mb-3'></div>
            <p className='ms-3' style={{height: '1.5rem'}}>
              <span className='align-middle'><i className='bx bx-wallet-alt'></i></span>
              <span className='ms-2'></span>
              <span className='align-middle'>Savings</span>
            </p>
            <div className='border-bottom mb-3'></div>
            <p className='ms-3' style={{height: '1.5rem'}}>
              <span className='align-middle'><i className='bx bx-credit-card'></i></span>
              <span className='ms-2'></span>
              <span className='align-middle'>Credit</span>
            </p>
            <div className='border-bottom mb-3'></div>
            <p className='ms-3' style={{height: '1.5rem'}}>
              <span className='align-middle'><i className='bx bx-purchase-tag'></i></span>
              <span className='ms-2'></span>
              <span className='align-middle'>Payments</span>              
            </p>
            <div className='border-bottom mb-3'></div>
            <p className='ms-3' style={{height: '1.5rem'}}>
              <span className='align-middle'><i className='bx bx-coin-stack'></i></span>
              <span className='ms-2'></span>
              <span className='align-middle'>Wealth</span>              
            </p>
            <div className='border-bottom mb-3'></div>
            <p className='ms-3' style={{height: '1.5rem'}}>
              <span className='align-middle'><i className='bx bx-file'></i></span>
              <span className='ms-2'></span>
              <span className='align-middle'>Tax</span>
            </p>
            <div className='border-bottom mb-3'></div>
            <p className='ms-3' style={{height: '1.5rem'}}>
              <span className='align-middle'><i className='bx bx-user-circle'></i></span>
              <span className='ms-2'></span>
              <span className='align-middle'>Identity</span>
            </p>
            <div className='border-bottom mb-3'></div>            
            <p className='ms-3 fw-bold' style={{height: '1.5rem'}}>Score</p>
            <div className='border-bottom d-none'></div>
          </div>

          <div className='bg-light' style={{width: '20%'}}>
            <p className='fw-bold mt-3 ms-3'>Rate</p>
            <div className='border-bottom mb-3'></div>
            <input
              type='text'
              className='form-control height-md m-0 mb-2 mx-3'
              style={{ fontSize: '0.9rem', height: '2rem', width: '60%' }}
              value={rate.rate1}
              onChange={({ target }) => { handleRateChange('rate1', target.value) }}
              disabled={loader}
              placeholder='0'
            ></input>
            <div className='border-bottom mb-3'></div>
            <input
              type='text'
              className='form-control height-md m-0 mb-2 mx-3'
              style={{ fontSize: '0.9rem', height: '2rem', width: '60%' }}
              value={rate.rate2}
              onChange={({ target }) => { handleRateChange('rate2', target.value) }}
              disabled={loader}
              placeholder='0'
            ></input>
            <div className='border-bottom mb-3'></div>
            <input
              type='text'
              className='form-control height-md m-0 mb-2 mx-3'
              style={{ fontSize: '0.9rem', height: '2rem', width: '60%' }}
              value={rate.rate3}
              onChange={({ target }) => { handleRateChange('rate3', target.value) }}
              disabled={loader}
              placeholder='0'
            ></input>
            <div className='border-bottom mb-3'></div>
            <input
              type='text'
              className='form-control height-md m-0 mb-2 mx-3'
              style={{ fontSize: '0.9rem', height: '2rem', width: '60%' }}
              value={rate.rate4}
              onChange={({ target }) => { handleRateChange('rate4', target.value) }}
              disabled={loader}
              placeholder='0'
            ></input>
            <div className='border-bottom mb-3'></div>
            <input
              type='text'
              className='form-control height-md m-0 mb-2 mx-3'
              style={{ fontSize: '0.9rem', height: '2rem', width: '60%' }}
              value={rate.rate5}
              onChange={({ target }) => { handleRateChange('rate5', target.value) }}
              disabled={loader}
              placeholder='0'
            ></input>
            <div className='border-bottom mb-3'></div>
            <input
              type='text'
              className='form-control height-md m-0 mb-2 mx-3'
              style={{ fontSize: '0.9rem', height: '2rem', width: '60%' }}
              value={rate.rate6}
              onChange={({ target }) => { handleRateChange('rate6', target.value) }}
              disabled={loader}
              placeholder='0'
            ></input>
            <div className='border-bottom mb-3'></div>
            <p className={`ms-3 text-${rates<=100 ? 'secondary' : 'danger'}`}>{rates == "" ? "000" : rates }</p>
            <div className='border-bottom d-none'></div>            
          </div>

          <div className='bg-light text-secondary' style={{width: '20%'}}>
            <p className='fw-bold mt-3 ms-3'>Baseline</p>
            <div className='border-bottom mb-3'></div>
            <p className='ms-3'>{baseline.base1}</p>
            <div className='border-bottom mb-3'></div>
            <p className='ms-3'>{baseline.base2}</p>
            <div className='border-bottom mb-3'></div>
            <p className='ms-3'>{baseline.base3}</p>
            <div className='border-bottom mb-3'></div>
            <p className='ms-3'>{baseline.base4}</p>
            <div className='border-bottom mb-3'></div>
            <p className='ms-3'>{baseline.base5}</p>
            <div className='border-bottom mb-3'></div>
            <p className='ms-3'>{baseline.base6}</p>
            <div className='border-bottom mb-3'></div>            
            <p className='ms-3 fw-bold'>{basescore}</p>
            <div className='border-bottom d-none'></div>
          </div> 

          <div className='bg-light' style={{width: '30%'}}>
            <p className='fw-bold mt-3 ms-3'>Data</p>
            <div className='border-bottom mb-3'></div>
            <input
              type='text'
              className='form-control height-md m-0 mb-2 mx-3'
              style={{ fontSize: '0.9rem', height: '2rem', width: '90%' }}
              value={data.data1}
              onChange={({ target }) => { handleChange('data1', target.value) }}
              disabled={loader || !rate.rate1}
              placeholder='0'
            ></input>
            <div className='border-bottom mb-3'></div>
            <input
              type='text'
              className='form-control height-md m-0 mb-2 mx-3'
              style={{ fontSize: '0.9rem', height: '2rem', width: '90%' }}
              value={data.data2}
              onChange={({ target }) => { handleChange('data2', target.value) }}
              disabled={loader || !rate.rate2}
              placeholder='0'
            ></input>
            <div className='border-bottom mb-3'></div>
            <input
              type='text'
              className='form-control height-md m-0 mb-2 mx-3'
              style={{ fontSize: '0.9rem', height: '2rem', width: '90%' }}
              value={data.data3}
              onChange={({ target }) => { handleChange('data3', target.value) }}
              disabled={loader || !rate.rate3}
              placeholder='0'
            ></input>
            <div className='border-bottom mb-3'></div>
            <input
              type='text'
              className='form-control height-md m-0 mb-2 mx-3'
              style={{ fontSize: '0.9rem', height: '2rem', width: '90%' }}
              value={data.data4}
              onChange={({ target }) => { handleChange('data4', target.value) }}
              disabled={loader || !rate.rate4}
              placeholder='0'
            ></input>
            <div className='border-bottom mb-3'></div>
            <input
              type='text'
              className='form-control height-md m-0 mb-2 mx-3'
              style={{ fontSize: '0.9rem', height: '2rem', width: '90%' }}
              value={data.data5}
              onChange={({ target }) => { handleChange('data5', target.value) }}
              disabled={loader || !rate.rate5}
              placeholder='0'
            ></input>
            <div className='border-bottom mb-3'></div>
            <input
              type='text'
              className='form-control height-md m-0 mb-2 mx-3'
              style={{ fontSize: '0.9rem', height: '2rem', width: '90%' }}
              value={data.data6}
              onChange={({ target }) => { handleChange('data6', target.value) }}
              disabled={loader || !rate.rate6}
              placeholder='0'
            ></input>
            <div className='border-bottom mb-3'></div>            
            <p className='ms-3 fw-bold'>{score}</p>
            <div className='border-bottom d-none'></div>        
          </div>
          
          <div className='bg-light text-end d-none' style={{width: '25%'}}>
            <p className='fw-bold mt-3 me-3'>Score</p>
            <div className='border-bottom mb-3'></div>
            <p className='me-3'>{(data.data1 / baseline.base1 * rate.rate1 * 9).toFixed(0)}</p>
            <div className='border-bottom mb-3'></div>
            <p className='me-3'>{(data.data2 / baseline.base2 * rate.rate2 * 9).toFixed(0)}</p>
            <div className='border-bottom mb-3'></div>
            <p className='me-3'>{(data.data3 / baseline.base3 * rate.rate3 * 9).toFixed(0)}</p>
            <div className='border-bottom mb-3'></div>
            <p className='me-3'>{(data.data4 / baseline.base4 * rate.rate4 * 9).toFixed(0)}</p>
            <div className='border-bottom mb-3'></div>
            <p className='me-3'>{(data.data5 / baseline.base5 * rate.rate5 * 9).toFixed(0)}</p>
            <div className='border-bottom mb-3'></div>
            <p className='me-3'>{(data.data6 / baseline.base6 * rate.rate6 * 9).toFixed(0)}</p>
            <div className='border-bottom mb-3'></div>            
            <p className='me-3 fw-bold'>{score}</p>
            <div className='border-bottom d-none'></div>
          </div>                     
        </div>
        
        <div className='ms-3 border p-3 rounded' style={{width: '45%'}}>
          <div className='' style={{width: ''}}>
            <div className='d-flex'>
              <div className='me-auto'><p className='lead fw-bold mb-2'>Truescore</p></div>
              <div className='text-end'><p className='mb-2'>
                <span className='lead fw-bold'>{score}</span>
                <span className=''>{' / 900'}</span>
                </p></div>
            </div>
            <div className='bg-light'>
              <div className="progress" style={{height: '0.6rem'}} role="progressbar" aria-label="Truescore" aria-valuenow={`${parseInt(score/9)}%`} aria-valuemin="0" aria-valuemax="100">
                <div className={`progress-bar bg-${score>630 ? 'success' : score > 330 ? 'warning' : 'danger'}`} style={{width: `${parseInt(score/9)}%`, height: '0.6rem'}}>{''}</div>
              </div>
            </div>

            <div className='mb-2'></div>
            <div className='d-flex d-none'>
              <div className='me-auto'><p className='lead mb-2'>Cibil</p></div>
              <div className='text-end'><p className='mb-2'>
                <span className='lead fw-bold'>{score}</span>
                <span className=''>{' / 900'}</span>
                </p></div>
            </div>
            <div className='bg-light d-none'>
              <div className="progress bg-secondary" style={{height: '0.5rem'}} role="progressbar" aria-label="Truescore" aria-valuenow={`${parseInt(score/9)}%`} aria-valuemin="0" aria-valuemax="100">
                <div className={`progress-bar bg-${score>630 ? 'success' : score > 330 ? 'warning' : 'danger'}`} style={{width: `${parseInt(score/9)}%`, height: '0.6rem'}}>{''}</div>
              </div>
            </div>                    

          </div>

          <div className='mb-3'></div>
          <p className='m-0 fw-bold text-secondary'>History</p>
          <div className='mt-4' style={{height:'350px'}}>
            <CreditScoreGraph data= {scorelist} />
          </div>

        </div>
      
      </div>

      <div className='mb-3'></div>
      <div className='d-flex justify-content-start'>
        <div className=''>
          <button className='btn btn-sm btn-light rounded border' onClick={() => handleBaseline(1)}>Baseline 1</button>
        </div>
        <div className='ms-2'></div>
        <div className=''>
          <button className='btn btn-sm btn-light rounded border' onClick={() => handleBaseline(2)}>Baseline 2</button>
        </div>
        <div className='ms-2'></div>
        <div className=''>
          <button className='btn btn-sm btn-light rounded border' onClick={() => handleBaseline(3)}>Baseline 3</button>
        </div>        
      </div>

      <div className='mb-3'></div>
      <div className='border rounded p-3'>
        <p className='m-0 fw-bold text-secondary'>Monitoring Alerts</p>
        <div className='mb-3'></div>

        <div className=''>
            {/* show alerts here */}
            {alerts && alerts.map((item, i) => (
             
            <p className='m-0 mb-2' style={{fontSize: '0.9rem'}} key={i}>
              <span className={`text-${item.status == 0 ? 'danger' : item.status == 1 ? 'warning' : item.status==6? 'success':'secondary'}`} style={{fontSize: '0.7rem'}}><i className='bx bxs-circle'></i></span>
              <span className='ms-2'></span>
              <span className=''>{new Date(item.created).toLocaleTimeString()}</span>
              <span className='ms-2'></span>
              <span className=''>{item.memo}</span>
            </p>
            ))}
        </div>

      </div>

    </>
  )
}