import { Routes, Route, Navigate } from 'react-router-dom'
import { useAuth } from '../context/authcontext'

// views - main
import About from '../views.xz/main-xz'


const routes = [
  { route: '/', content: <About />, auth: false },

]

export default function RouteX () {
  const { user } = useAuth()
  //console.log('user', user)

  return (
    <Routes>
      {routes.map((item, i) =>
        item.auth ? (
          <Route key={i} path={item.route} element={!user 
            ? ( <Navigate to='/auth' state={{ location: window.location.pathname }} /> ) 
            : ( item.content )
            }
          />
        ) : (
          <Route key={i} path={item.route} element={item.content} />
        )
      )}
    </Routes>
  )
}