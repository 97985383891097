import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";

const CreditScoreGraph = (props) => {
  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  // Convert string data to numbers initially
  const initialData =  props.data.map((item) => parseFloat(item));

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");

    const data = {
      labels: initialData.map((_, index) => (index + 1)),
      datasets: [
        {
          label: "Score",
          data: initialData,
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 2,
          fill: {
            target: "origin",
            above: "rgba(75, 192, 192, 0.1)", // High opacity
            below: "rgba(75, 192, 192, 0)", // Low opacity
          },
          tension: 0.4, // Adjust the tension for a smoother curve
          pointRadius: 0,
        },
      ],
    };

    const config = {
      type: "line",
      data: data,
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          layout: {
            padding: {
              top: 20,
              bottom: 20,
              left: 20,
              right: 20,
            },
          },
          tooltip: {
            mode: "index",
            intersect: false,
          },
        },
        scales: {
          x: {
            display: false, // Hide the x-axis labels
            grid: {
              display: false,
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              display: true,
            },
            min:0,
            max: 900,
            ticks: {
                stepSize: 100,
              },
            // title: {
            //   display: true,
            //   text: "Score →",
            //   align: "center",
            // },
          },
        },
      },
    };

    const newChartInstance = new Chart(ctx, config);
    setChartInstance(newChartInstance);

    return () => {
      newChartInstance.destroy();
    };
  }, [chartRef]);

  // Update chart when props.data changes
  useEffect(() => {
    if (chartInstance) {
      chartInstance.data.datasets[0].data = props.data.map((item) =>
        parseFloat(item)
      );
      chartInstance.data.labels = props.data.map((_, index) => index + 1);
      chartInstance.update();
    }
  }, [props.data, chartInstance]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default CreditScoreGraph;