//content
import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import CalculatorModule from "../content/calculator";
import CalculatorModuleNew from '../content/calculator-new';


const metadata = {
  name: 'Welcome',
  banner: {link: 'https://img.freepik.com/free-vector/abstract-glowing-particles-background-blue-red-color_1017-26575.jpg?w=900'}
}

export default function Main () {

  const navigate = useNavigate()

  const [score, setScore] = useState('000')

  const handleScore = async (scorex) => {
    setScore(scorex)
  }

  return(

  <>
    <Helmet>
      <title>{metadata.name}{' • '}{process.env.REACT_APP_WEBB_SITE_NAME}{' • '}{process.env.REACT_APP_WEBB_SITE_LINE}</title>
      <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
    </Helmet>

    <div className='border-bottom'>
      <div className='mb-3'></div>
      <div className='container'>
        <p className='fw-bold lead m-0 text-success'>Truescore Calculator (Demo)</p>
      </div>
      <div className='mb-3'></div>
    </div>

    <div className='mb-3'></div>
    <div className='mb-3'></div>

    <div className='container'>
      {/* <CalculatorModule /> */}
      <CalculatorModuleNew />
      
    </div>
    
    

  </>
  )
}